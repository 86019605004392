
function OfflinePage() {
    return (
        <div className="offline-page">
            <div>
                <h1>OOPS!</h1>
                <h2>You are currently offline</h2>
                <p>Please check your internet connection and try again.</p>
            </div>
        </div>
    )
}

export default OfflinePage