import { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import Logo from "../assets/images/blink_logo.png";
import gsap from "gsap";

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const [isAtTop, setIsAtTop] = useState(true);

  const handleScroll = () => {
    setIsAtTop(window.scrollY === 0);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const menuRef = useRef(null);
  const linksRef = useRef([]);

  const tl = useRef(gsap.timeline({ paused: true }));
  let mm = gsap.matchMedia();

  useEffect(() => {
    mm.add("(max-width: 800px)", () => {
      tl.current.to(menuRef.current, {
        top: 0,
        duration: 1,
        opacity: 1,
        height: "100vh",
        ease: "expo.inOut",
      });
      tl.current.from(
        linksRef.current,
        {
          duration: 1,
          opacity: 0,
          y: 20,
          stagger: 0.1,
          ease: "expo.inOut",
        },
        "-=0.5"
      );

      // Initial reverse state
      tl.current.reverse();
    });

    return () => {
      tl.current.kill();
    };
  }, []);

  const toggleMenu = () => {
    tl.current.reversed(!tl.current.reversed());
    setIsMenuOpen(!isMenuOpen);
  };

  const navLinks = [
    { label: "Work", path: "/works" },
    // { label: "Culture", path: "/culture" },
    { label: "News", path: "/news" },
    // { label: "Thinking", path: "/thinking" },
    // { label: "Jobs", path: "/jobs" },
    { label: "Contact", path: "/contact" },
    { label: "Initiatives", path: "/initiatives" },
    { label: "People", path: "/people" },
  ];

  return (
    <header className={isAtTop ? "header" : "header scroll-top"}>
      <div className="header-wrapper container">
        <div className="logo">
          <Link to="/">
            <img src={Logo} alt="Logo" />
          </Link>
        </div>
        <div className="menu-icon" onClick={toggleMenu}>
          <div className={isMenuOpen ? "hamburger open" : "hamburger"}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <nav
          className={isMenuOpen ? "nav-links open" : "nav-links"}
          ref={menuRef}
        >
          <ul className="wrap-links">
            {navLinks.map((link, index) => (
              <li key={index} ref={(el) => (linksRef.current[index] = el)}>
                <Link
                  to={link.path}
                  onClick={() => {
                    setIsMenuOpen(false);
                    tl.current.reversed(!tl.current.reversed());
                  }}
                >
                  {link.label}
                </Link>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </header>
  );
}

export default Header;
