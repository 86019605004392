import React, { useState } from "react";
import Lightbox from "yet-another-react-lightbox";

function DynamicLightbox({ image }) {
  const [open, setOpen] = useState(false);

  const handleImageClick = () => {
    setOpen(true);
  };

  const isMobile = window.innerWidth <= 768; // Example condition for mobile view

  const style = {
    borderRadius: isMobile ? "10px" : "40px",
    width: "100%",
    overflow: "hidden", // Conditional padding based on mobile view
  };

  return (
    <div>
      <div style={style}>
        <img
          src={image}
          onClick={handleImageClick}
          style={{ cursor: "pointer", width: "100%", height: "auto" }}
        />
      </div>
      {open && (
        <Lightbox
          open={open}
          close={() => setOpen(false)}
          slides={[{ src: image, title: "" }]}
          carousel={{ finite: image.length <= 1 }}
          render={{
            buttonPrev: image.length <= 1 ? () => null : undefined,
            buttonNext: image.length <= 1 ? () => null : undefined,
          }}
        />
      )}
    </div>
  );
}

export default DynamicLightbox;
