import React from "react";
import Slider from "react-slick";
import RikkiImg from "../assets/images/Rikki.jpg";
import DiaImg from "../assets/images/Dia.jpg";
import DoojImg from "../assets/images/Dooj-new.jpg";
import PrernaImg from "../assets/images/Prerna.jpg";
import MichellImg from "../assets/images/Michell-new.jpg";
import KhushbooImg from "../assets/images/Khushboo.jpg";
function TeamSlider({}) {
  const sliderRef = React.useRef(null);

  const settings = {
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    speed: 10000, // Adjust speed for continuous effect
    autoplaySpeed: 0, // Disable autoplay delay
    cssEase: "linear", // Ensure continuous smooth scrolling
    pauseOnHover: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          centerPadding: "50px",
        },
      },
      {
        breakpoint: 768,
        settings: {
          // slidesToShow: 1.85,
          // slidesToScroll: 1,
          // infinite: true,
          // dots: false,
          // centerPadding: "50px",
          speed: 10000,
          autoplay: true,
          autoplaySpeed: 0,
          cssEase: "linear",
          slidesToShow: 1.85,
          slidesToScroll: 1,
          dots: false,
          centerPadding: "50px",
        },
      },
    ],
  };

  const handleMouseEnter = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPause();
    }
  };

  const handleMouseLeave = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPlay();
    }
  };

  return (
    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <Slider ref={sliderRef} {...settings}>
        <a>
          <div
            className="team-card  m-100"
            // style={{ backgroundImage: `url(${RikkiImg})` }}
          >
            <div className="black-overlay"></div>
            <img src={RikkiImg} />
            <div className="team-name">
              <p className="">Rikki Agarwal</p>
              <p className="designation">Co-founder & COO</p>
            </div>
          </div>
        </a>
        <a>
          <div
            className="team-card  m-150"
            // style={{ backgroundImage: `url(${DiaImg})` }}
          >
            <div className="black-overlay"></div>
            <img src={DiaImg} />

            <div className="team-name">
              <p className="">Dia Kirpalani</p>
              <p className="designation">VP, Head of Strategy</p>
            </div>
          </div>
        </a>
        <a>
          <div
            className="team-card  m-100"
            // style={{ backgroundImage: `url(${DoojImg})` }}
          >
            <div className="black-overlay"></div>
            <img src={DoojImg} />
            <div className="team-name">
              <p className="">Dooj Ramchandani</p>
              <p className="designation">Co-founder & CEO</p>
            </div>
          </div>
        </a>
        <a>
          <div
            className="team-card  m-200"
            // style={{ backgroundImage: `url(${KhushbooImg})` }}
          >
            <div className="black-overlay"></div>
            <img src={KhushbooImg} />

            <div className="team-name">
              <p>Khushboo Bhatt</p>
              <p className="designation">AVP - Creative Growth</p>
            </div>
          </div>
        </a>
        <a>
          <div
            className="team-card m-50"
            // style={{ backgroundImage: `url(${MichellImg})` }}
          >
            <div className="black-overlay"></div>
            <img src={MichellImg} />
            <div className="team-name">
              <p>Michell Anthony</p>
              <p className="designation">Director - Human Resource</p>
            </div>
          </div>
        </a>
        {/* <a>
          <div
            className="team-card m-150"
            style={{ backgroundImage: `url(${RikkiImg})` }}
          >
            <p>Rikki Agarwal</p>
          </div>
        </a> */}
        <a>
          <div
            className="team-card m-200"
            // style={{ backgroundImage: `url(${PrernaImg})` }}
          >
            {" "}
            <div className="black-overlay"></div>
            <img src={PrernaImg} />
            <div className="team-name">
              <p>Prerana Parab</p>
              <p className="designation">Associate Creative Director - Art</p>
            </div>
          </div>
        </a>
      </Slider>
    </div>
  );
}

export default TeamSlider;
