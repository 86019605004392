import { api } from '../api';

export const newsApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getNews: builder.query({
            query: ({ currentPage, limit, sortBy }) => ({
                url: `/articles?sort=publish_date:${sortBy}&pagination[page]=${currentPage}&pagination[pageSize]=${limit}&populate=*`,
                method: 'GET',
            }),
            transformResponse: (response, meta, arg) => response,
        }),

    })
})

export const { useGetNewsQuery } = newsApi;