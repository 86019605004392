import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useLayoutEffect, useRef } from "react";
import Ads1 from "../assets/images/ads.png";
import Ads2 from "../assets/images/ads2.png";
import Animation from "../assets/images/animation-&-motion-graphics.png";
import Games from "../assets/images/games2.png";
import Metaverse from "../assets/images/metaverse.png";
import Social from "../assets/images/social.png";
import Video from "../assets/images/video.png";
import Web from "../assets/images/web.png";
import { useState, useEffect } from "react";
import useWindowDimensions from "components/useWindowDimensions";

gsap.registerPlugin(ScrollTrigger);

const AnimatedAboutUs = () => {
  const containerRef = useRef(null);
  const [isMobileView, setisMobileView] = useState(false);

  const { width: deviceWidth } = useWindowDimensions();

  const images = [
    { src: Web, text: "website and mobile site" },
    { src: Metaverse, text: "metaverse immersive virtual" },
    { src: Ads1, text: "apps and software" },
    { src: Games, text: "games" },
    { src: Animation, text: "animation and motion graphics" },
    { src: Social, text: "social" },
    { src: Video, text: "video" },
    { src: Ads2, text: "ads, media & PR" },
  ];

  const desktopPositions = [
    { x: "-200%", y: "-150%" },
    { x: "180%", y: "-150%" },
    { x: "-160%", y: "130%" },
    { x: "190%", y: "150%" },
    { x: "0%", y: "-155%" },
    { x: "20%", y: "165%" },
    { x: "-210%", y: "0%" },
    { x: "240%", y: "0%" },
  ];

  const mobilePositions = [
    { x: "-120px", y: "-230px" },
    { x: deviceWidth / 2 - 20 - 50, y: "-120px" },
    { x: "-115px", y: "110px" },
    { x: "100px", y: "240px" },
    { x: "50px", y: "-250px" },
    { x: "-70px", y: "270px" },
    { x: -Math.abs(deviceWidth / 2 - 120 + 40), y: "-75px" },
    { x: "90px", y: "105px" },
  ];

  const sizes = [
    { width: "150px" },
    { width: "200px" },
    { width: "200px" },
    { width: "150px" },
    { width: "220px" },
    { width: "180px" },
    { width: "240px" },
    { width: "220px" },
  ];

  const mobileSizes = sizes.map((size) => ({
    width: parseFloat(size.width) / 2 + "px",
  }));

  const mm = gsap.matchMedia();

  useEffect(() => {
    mm.add("(max-width: 800px)", () => {
      setisMobileView(true);
    });
  }, []);

  useLayoutEffect(() => {
    const container = containerRef.current;
    const setupImages = (positions, sizes) => {
      // Clear existing images
      const existingImages = container.querySelectorAll(".image-container");
      existingImages.forEach((imgContainer) => imgContainer.remove());

      images.forEach((imageData, index) => {
        const { src, text } = imageData;
        const imageContainer = document.createElement("div");
        imageContainer.className = "image-container";

        const image = document.createElement("img");
        image.src = src;
        image.className = "image";
        imageContainer.appendChild(image);

        const textOverlay = document.createElement("div");
        textOverlay.className = "text-overlay";
        textOverlay.textContent = text;
        imageContainer.appendChild(textOverlay);

        container.appendChild(imageContainer);

        // Calculate the position and size based on the index
        const { x, y } = positions[index] || { x: "0%", y: "0%" };
        const { width } = sizes[index] || { width: "100px" };

        gsap.set(imageContainer, {
          position: "absolute",
          width,
          opacity: 1,
          transformOrigin: "center center",
        });

        // console.log(x, y);

        gsap.to(imageContainer, {
          // xPercent: parseFloat(x),
          // yPercent: parseFloat(y),
          x: x,
          y: y,
          opacity: 1,
          duration: 4,
          scrollTrigger: {
            trigger: container,
            start: isMobileView ? "top top" : "top 90%",
            end: "+=500",
            scrub: true,
            //markers: true
          },
        });
      });
    };

    mm.add("(min-width: 800px)", () => {
      setupImages(desktopPositions, sizes);
    });

    mm.add("(max-width: 799px)", () => {
      setupImages(mobilePositions, mobileSizes);
    });

    return () => {
      mm.revert();
      // Cleaning up all ScrollTrigger instances when the component unmounts
      ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
    };
  }, []);

  return (
    <div className="scatter-section" ref={containerRef}>
      <div className="center-text">
        create the best <br /> of the internet
      </div>
    </div>
  );
};

export default AnimatedAboutUs;
