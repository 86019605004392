import React from "react";
import { Link, Outlet, useLocation } from "react-router-dom";

function Footer() {
  const location = useLocation();
  const isHomepage = location.pathname == "/";
  const isWorks = location.pathname == "/works";
  const isNews = location.pathname == "/news";
  const isWorkPage = location.pathname == "works/:id";
  const isInitiatives = location.pathname == "/initiatives";
  const style = isWorks
    ? { borderBottom: "1px solid black" }
    : isHomepage
    ? { borderBottom: "1px solid black" }
    : { borderBottom: "1px solid black" };

  return (
    <footer
      className={
        isWorks
          ? "white-footer"
          : isHomepage
          ? "white-footer"
          : isNews
          ? "white-footer"
          : isInitiatives
          ? "white-footer"
          : isWorkPage
          ? ""
          : "white-footer"
      }
      data-scroll-section
    >
      <div className="footer-wrapper container">
        <div className="footer-item order-0">
          <div className="footer-wrap">
            <h4 className="head" style={style}>
              General Enquiries
            </h4>
            <a className="email" href="mailto:info@blinkdigital.in">
              info@blinkdigital.in
            </a>
            <h4 className="head-b" style={style}>
              New business
            </h4>
            <a className="email" href="mailto:newbusiness@blinkdigital.in">
              newbusiness@blinkdigital.in
            </a>
            <h4 className="head-b" style={style}>
              Careers
            </h4>
            <a className="email" href="mailto:careers@blinkdigital.in">
              careers@blinkdigital.in
            </a>
          </div>
        </div>
        <div className="footer-item order-1">
          <div className="footer-wrap">
            <h4 className="head" style={style}>
              Offices
            </h4>
            <p className="city">Mumbai</p>
            <p className="city">Dubai</p>
            <p className="city">Amsterdam</p>
          </div>
        </div>
        <div className="footer-item order-2">
          <div className="footer-wrap">
            <h4 className="head" style={style}>
              Info
            </h4>
            {/* <p className="link">About</p> */}
            <Link to={"/works"} className="link">
              <p>Work</p>
            </Link>
            {/* <p className="link">Capability</p>
          <p className="link">Team</p>
          <p className="link">Awards</p> */}
            <Link to={"/contact"} className="link">
              <p>Contact</p>
            </Link>
            <Link to={"/news"} className="link">
              <p>News</p>
            </Link>
            <Link to={"/initiatives"} className="link">
              <p>Initiatives</p>
            </Link>
          </div>
        </div>
        <div className="footer-item order-3">
          <div className="footer-wrap">
            <h4 className="head" style={style}>
              Social
            </h4>
            <a
              href="https://www.instagram.com/blink.digital"
              className="link"
              target="_blank"
            >
              <p> Instagram</p>
            </a>
            <a
              href="https://www.linkedin.com/company/blink-digital"
              className="link"
              target="_blank"
            >
              <p>Linkedin</p>
            </a>
            <a
              href="https://vimeo.com/blinkdigital"
              className="link"
              target="_blank"
            >
              <p>Vimeo</p>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
