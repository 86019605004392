import { api } from '../api';

export const initiativesApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getInitiatives: builder.query({
            query: () => ({
                url: '/initiatives?populate=*',
                method: 'GET',
            }),
            transformResponse: (response, meta, arg) => response.data,
        }),

    })
})

export const { useGetInitiativesQuery } = initiativesApi;