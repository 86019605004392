import { useState } from "react";

import {
  useGetContactsQuery,
  useGetOfficesQuery,
  useGetTimeZonesQuery,
} from "store/contact/contactApi";
import ArrowPng from "../assets/images/ArrowLocation.png";
import WolrdMap from "../assets/images/bg-world.png";
// import WolrdMap from "../assets/images/Wavy_BG.gif";
// import WolrdMap from "../assets/images/website_map_v01.gif";

function Contact() {
  const [activeTimezone, setactiveTimezone] = useState("all");

  const { data: offices, error, isLoading } = useGetOfficesQuery();
  const { data: contacts, isContactsLoading } = useGetContactsQuery();
  const { data: timezones, isTimeZonesLoading } = useGetTimeZonesQuery();

  const officeList = offices?.map((office, index) => {
    return (
      <div
        style={{
          display:
            activeTimezone ==
              office?.attributes?.timezone?.data?.attributes?.title ||
            activeTimezone == "all"
              ? "flex"
              : "none",
        }}
        className={"address-item"}
        key={`offices_${index}`}
        data-timezone={office?.attributes?.timezone?.data?.attributes?.title}
      >
        <h4 className="city">{office?.attributes?.title}</h4>
        <a className="adress" href={office?.attributes?.link} target="_blank">
          <h4>Address</h4>
          <div className="adr-tag-wrap">
            <div className=" rolling-copy-wrap">
              <div className="text">{office?.attributes?.address} </div>
              <div className="icon">
                {/* <FontAwesomeIcon icon={faArrowRight} /> */}
                <img src={ArrowPng} />
              </div>
            </div>
          </div>
        </a>
      </div>
    );
  });

  const timezonesList = timezones?.map((timezone, index) => {
    return (
      <a
        className={
          activeTimezone == timezone?.attributes?.title
            ? "timezone-filter active"
            : "timezone-filter"
        }
        onClick={() => setactiveTimezone(timezone?.attributes?.title)}
        key={`timezonesItem_${index}`}
      >
        {timezone?.attributes?.title}
      </a>
    );
  });

  return (
    <div id="contact-page" data-scroll-section>
      <div className="contact-wrapper container">
        <div className="world-contact-map">
          <div className="heading-contact">Catch us around the globe</div>
          <img className="world-map" src={WolrdMap} alt="wolrd" />
          {/* <video controls autoPlay loop muted>
            <source src={WolrdMap} type="video/mov"></source>
          </video> */}

          <div className="info-card">
            <div className="card-item">
              <h4>General Enquiries</h4>
              <a
                href={`mailto:${contacts?.attributes?.general_enquiries}`}
                target="_blank"
              >
                {contacts?.attributes?.general_enquiries}
              </a>
            </div>
            <div className="card-item">
              <h4>New Business</h4>
              <a
                href={`mailto:${contacts?.attributes?.new_business}`}
                target="_blank"
              >
                {contacts?.attributes?.new_business}
              </a>
            </div>
            <div className="card-item">
              <h4>Careers</h4>
              <a href={contacts?.attributes?.careers} target="_blank">
                {contacts?.attributes?.careers}
              </a>
            </div>
          </div>
        </div>
        <div className="our-offices">
          <h3 className="office-head">Our Offices</h3>
          <div className="filter">
            {timezonesList}
            {activeTimezone != "all" ? (
              <a
                className="reset-timezones"
                onClick={() => setactiveTimezone("all")}
              >
                Reset
              </a>
            ) : null}
          </div>
          <div
            className={
              activeTimezone != "all"
                ? "adress-wrapper filter-applied"
                : "adress-wrapper"
            }
          >
            {officeList}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
