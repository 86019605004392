import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

ScrollTrigger.config({ limitCallbacks: true });

const LazyLoad = ({ src, dataSrc, type = "img", ...props }) => {
    const elementRef = useRef(null);

    useEffect(() => {
        const element = elementRef.current;

        let newSRC = dataSrc;
        let newElement;

        if (type === "img") {
            newElement = document.createElement("div");
            newElement.style.width = `${element.offsetWidth}px`;
            newElement.style.height = `${element.offsetHeight}px`;
            newElement.style.position = "relative";
        } else if (type === "video") {
            // Use the 'canplay' event for video elements
            newElement = document.createElement("video");
            newElement.oncanplay = () => {
                newElement.oncanplay = null; // avoid recursion
                newElement.src = element.src; // swap the src
                element.src = newSRC;

                // place the low-res version on TOP and then fade it out.
                gsap.set(newElement, {
                    position: "absolute",
                    top: element.offsetTop,
                    left: element.offsetLeft,
                    width: element.offsetWidth,
                    height: element.offsetHeight,
                });

                element.parentNode.appendChild(newElement);
                gsap.to(newElement, {
                    opacity: 0,
                    onComplete: () => newElement.parentNode.removeChild(newElement),
                });
                st && st.kill();
            };
        }

        const loadImage = () => {
            // For image elements, use the 'onload' event
            newElement.onload = () => {
                newElement.onload = null; // avoid recursion
                newElement.src = element.src; // swap the src
                element.src = newSRC;

                // place the low-res version on TOP and then fade it out.
                gsap.set(newElement, {
                    position: "absolute",
                    top: element.offsetTop,
                    left: element.offsetLeft,
                    width: element.offsetWidth,
                    height: element.offsetHeight,
                });

                element.parentNode.appendChild(newElement);
                gsap.to(newElement, {
                    opacity: 0,
                    onComplete: () => newElement.parentNode.removeChild(newElement),
                });
                st && st.kill();
            };

            newElement.src = newSRC;
        };

        const st = ScrollTrigger.create({
            trigger: element,
            start: "-50% bottom",
            onEnter: loadImage,
            //markers: true,
            onEnterBack: loadImage, // make sure it works in either direction
        });

        return () => {
            st && st.kill();
        };
    }, [dataSrc, type]);

    if (type === "img") {
        return (
            <div ref={elementRef}>
                <img
                    src={src}
                    alt={props.alt}
                    className={props.className}
                    {...props}
                />
            </div>
        );
    } else if (type === "video") {
        return (
            <video
                muted={true}
                playsInline={true}
                autoPlay={true}
                loop={true}
                controls={false}
                ref={elementRef}
                src={src}
                {...props}
            />
        );
    }

    return null;
};

export default LazyLoad;