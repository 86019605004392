import moment from "moment";
import { useEffect, useState } from "react";
import Select from "react-select";
import { useGetNewsQuery } from "store/news/newsApi";
import ArrowPng from "../assets/images/ArrowLocation.png";
import LazyLoad from "components/LazyLoad";

function News() {
  const limit = 12;
  const [currentPage, setcurrentPage] = useState(1);
  const [newsItems, setnewsItems] = useState([]);
  const [sortBy, setsortBy] = useState({ value: 'desc', label: 'Newest' });

  const {
    data: news,
    isLoading,
    isFetching,
    refetch
  } = useGetNewsQuery({ currentPage, limit, sortBy: sortBy?.value });

  useEffect(() => {
    if (news?.data?.length > 0) {
      if (currentPage > 1) {
        setnewsItems((prevItems) => [...prevItems, ...news?.data]);
      } else {
        setnewsItems(news?.data);
      }
    }
  }, [news]);

  useEffect(() => {
    refetch();
  }, [sortBy, currentPage])


  const colourStyles = {
    menu: (provided) => ({
      ...provided,
      borderRadius: "10px",
      padding: "0 10px",
    }),
    singleValue: (provided) => ({
      ...provided,
    }),
    control: (styles) => ({
      ...styles,
      backgroundColor: "transparent",
      borderColor: "black",
      borderRadius: 25,
      boxShadow: "none",
      minWidth: '110px',
      ":hover": {
        borderColor: "black",
        boxShadow: "none",
        outline: "none",
      },
    }),
    option: (styles, { isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
            ? "#f2f2f2"
            : isFocused
              ? "#f2f2f2"
              : undefined,
        color: isDisabled ? "#ccc" : isSelected ? "#000" : "#000",
        cursor: isDisabled ? "not-allowed" : "default",

        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled
            ? isSelected
              ? "#FDAF9B"
              : "#AFC4FF"
            : undefined,
        },
      };
    },
    input: (styles) => ({ ...styles, }),
    placeholder: (styles) => ({ ...styles, padding: "0 5px", paddingLeft: '15px' }),
    singleValue: (styles, { data }) => ({ ...styles }),
    indicatorsContainer: (provided, state) => ({
      ...provided,

    }),
    clearIndicator: (provided, state) => ({
      ...provided,
      display: 'none'
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: 'black'
    }),
    indicatorSeparator: (provided) => ({
      display: 'none',
    }),
  };

  //console.log(news);

  const newsCards = newsItems?.map((item, index) => {
    return (
      <a
        href={item?.attributes?.url}
        className="news-item"
        key={`news-item_${index}`}
        target="_blank"
      >
        <div className="tumbnail-img hover-image-container">
          <LazyLoad
            src={item?.attributes?.thumbnail?.data?.attributes?.url}
            dataSrc={item?.attributes?.thumbnail?.data?.attributes?.url}
          />
        </div>
        <div className="copy-wrap">
          <p className="date">
            {moment(item?.attributes?.publish_date).format("MMMM YYYY")}
          </p>
          <div className="rolling-copy-wrap">
            <p className="news-title">{item?.attributes?.title}</p>
          </div>
        </div>
        <div className="arrow-wrapper rolling-copy-wrap">
          <img src={ArrowPng} />
        </div>
      </a>
    );
  });

  return (
    <div id="news" data-scroll-section>
      <div className="container">
        <div className="news-header">
          <h2>news</h2>
          <div className="filter-wrapper">
            <span className="label">Sort by</span>
            <Select
              value={sortBy}
              onChange={(input) => setsortBy(input)}
              options={[{ value: 'desc', label: 'Newest' }, { value: 'asc', label: 'Oldest' }]}
              className="custom-select"
              styles={colourStyles}
            />
          </div>
        </div>
        <div className="divider"></div>
        <div className="news-list">
          {isLoading ? (
            <div className="spinner spinner-news">
              <span></span>
            </div>
          ) : (
            newsCards
          )}
        </div>

        {currentPage < news?.meta?.pagination?.pageCount && (
          <div className="show-more-wrap">
            {isFetching ? (
              <div className="spinner spinner-works">
                <span></span>
              </div>
            ) : (
              <a
                className="show-more-button"
                onClick={() => setcurrentPage((prevPage) => prevPage + 1)}
              >
                Show More
              </a>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default News;
