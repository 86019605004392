import React, { useEffect, useRef, useState } from 'react';
import gsap from 'gsap';

const NumberCountTicker = ({ value = 0 }) => {
    const numberRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const observerOptions = {
            root: null, // Use the viewport as the root
            rootMargin: '0px',
            threshold: [0, 0.5, 1], // Trigger when the element is 50% in the viewport
        };

        const observer = new IntersectionObserver(
            ([entry]) => {

                if (entry.isIntersecting) {
                    setTimeout(() => {
                        setIsVisible(true);
                    }, 500)
                }
            },
            observerOptions
        );

        // console.log(observer);

        if (numberRef.current) {
            observer.observe(numberRef.current);
        }

        return () => {
            observer.disconnect();
        };
    }, []);


    useEffect(() => {
        if (isVisible) {
            const numberElement = numberRef.current;
            const tl = gsap.timeline();

            tl.to(numberElement, {
                duration: 1.5,
                innerHTML: value,
                roundProps: 'innerHTML',
                ease: 'power3.out'
            });
        }
    }, [isVisible, value]);

    return <span ref={numberRef}></span>;
};

export default NumberCountTicker;