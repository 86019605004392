import { useGetInitiativesQuery } from "store/initiatives/initiativesApi";
import ArrowPng from "../assets/images/ArrowLocation.png";

function Initiatives() {
  const { data: initiatives, isLoading } = useGetInitiativesQuery();

  // const tagColors = ["#db7bff", "#a89cfb", "#57ddc3", "#FCA587"]; // Array of background colors
  const tags = ["D2C", "Feature Film", "Web3", "Gaming"]; // Array of background colors

  const cardsHTML = initiatives?.map((item, index) => {
    const url = item?.attributes?.thumbnail?.data?.attributes?.url;

    console.log(url);

    return (
      <a
        href={item?.attributes?.link}
        className="it-card"
        key={`initiative-card_${index}`}
        target="_blank"
        rel="noreferrer"
      >
        <div className="hover-image-container">
          <img src={url} alt="card-1" />
        </div>
        <div className="copy-wrapper">
          <div className="top-copy">
            <div
              className="tags-container"
              // style={{ backgroundColor: tagColors[index] }}
            >
              {tags[index]}
            </div>

            <div className="rolling-copy-wrap">
              <h3 className="discr">{item?.attributes?.title}</h3>
            </div>
            <p className="copy-discription">{item?.attributes?.description}</p>
          </div>
          <div className="more">
            <p>visit site </p>{" "}
            <span>
              <img src={ArrowPng} alt="arrowpng"></img>
            </span>
          </div>
        </div>
      </a>
    );
  });

  return (
    <div id="initatives" data-scroll-section>
      <div className="initatives-wrapper container">
        <div className="initatives-flex">
          <div className="flex-item">
            <h2 className="it-heading">Initiatives</h2>
          </div>
          <div className="flex-item flex-item-it">
            {isLoading ? (
              <div className="spinner spinner-int">
                <span></span>
              </div>
            ) : (
              cardsHTML
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Initiatives;
