import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    userInfo: null,
    userDashboard: null
};

export const authSlice = createSlice({
    initialState,
    name: 'authSlice',
    reducers: {
        logout: (state) => {
            Object.assign(state, initialState)
            localStorage.removeItem('kfcUser')
        },
        setUser: (state, { payload }) => {
            state.userInfo = payload;
        },
        setUserDasboard: (state, { payload }) => {
            state.userDashboard = payload;
        },
    },
});

export default authSlice.reducer;

export const { logout, setUser, setUserDasboard } = authSlice.actions;