import React from "react";
import Dooj from "../assets/images/Dooj-new.jpg";
import Khushboo from "../assets/images/Khushboo.jpg";
import Nikole from "../assets/images/Nicole.jpg";
import Renne from "../assets/images/renne.jpg";
import Rikki from "../assets/images/Rikki.jpg";
import Michell from "../assets/images/Michell-new.jpg";
import Prerna from "../assets/images/Prerna.jpg";
import Dia from "../assets/images/Dia.jpg";
import Jana from "../assets/images/Jana_1.jpg";
import Yogesh from "../assets/images/Yogesh.jpg";
function People() {
  return (
    <div id="people">
      <div className="people-wrapper container">
        <h2 className="contact-heading">People</h2>
        <div className="people-card-wrapper">
          {/* dooj */}
          <div className="people-card">
            <div className="img-wrap">
              <img src={Dooj} alt="name-people" />
            </div>
            <h2 className="name">Dooj Ramchandani</h2>
            <p className="designation">Co-Founder & CEO</p>
          </div>

          {/* rikki */}
          <div className="people-card">
            <div className="img-wrap">
              <img src={Rikki} alt="name-people" />
            </div>
            <h2 className="name">Rikki Agarwal</h2>
            <p className="designation">Co-Founder & COO</p>
          </div>

          {/* dia */}
          <div className="people-card">
            <div className="img-wrap">
              <img src={Dia} alt="name-people" />
            </div>
            <h2 className="name">Dia Kirpalani</h2>
            <p className="designation">VP, Head of Strategy</p>
          </div>

          {/* nikole */}
          <div className="people-card">
            <div className="img-wrap">
              <img src={Nikole} alt="name-people" />
            </div>
            <h2 className="name">Nicole Ferraz</h2>
            <p className="designation">Executive Creative Director</p>
          </div>

          {/* renne */}
          <div className="people-card">
            <div className="img-wrap">
              <img src={Renne} alt="name-people" />
            </div>
            <h2 className="name">Renee Mitra</h2>
            <p className="designation">VP - Client Services</p>
          </div>

          {/* sadvi */}
          <div className="people-card">
            <div className="img-wrap">
              <img src={Dooj} alt="name-people" />
            </div>
            <h2 className="name"> Sadhvi Dhawan </h2>
            <p className="designation">VP - Media</p>
          </div>

          {/* khushboo */}
          <div className="people-card">
            <div className="img-wrap">
              <img src={Khushboo} alt="name-people" />
            </div>
            <h2 className="name">Khushboo Bhatt</h2>
            <p className="designation">AVP - Creative Growth</p>
          </div>

          {/* Amer */}
          <div className="people-card">
            <div className="img-wrap">
              <img src={Dooj} alt="name-people" />
            </div>
            <h2 className="name"> Amer Ahmad</h2>
            <p className="designation"> Director - Technology</p>
          </div>

          {/* yogesh */}
          <div className="people-card">
            <div className="img-wrap">
              <img src={Yogesh} alt="name-people" />
            </div>
            <h2 className="name">Yogesh Shirke</h2>
            <p className="designation"> Creative Director - Art</p>
          </div>
          {/* yogesh */}
          <div className="people-card">
            <div className="img-wrap">
              <img src={Jana} alt="name-people" />
            </div>
            <h2 className="name">Jana Colaco</h2>
            <p className="designation"> Creative Director </p>
          </div>

          {/* prerna */}
          <div className="people-card">
            <div className="img-wrap">
              <img src={Prerna} alt="name-people" />
            </div>
            <h2 className="name">Prerana Parab</h2>
            <p className="designation">Associate Creative Director - Art</p>
          </div>

          {/* michell */}
          <div className="people-card">
            <div className="img-wrap">
              <img src={Michell} alt="name-people" />
            </div>
            <h2 className="name">Michell Anthony</h2>
            <p className="designation">Director - Human Resources</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default People;
