import { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import {
  useGetCompaniesQuery,
  useGetIndustriesQuery,
  useGetServicesQuery,
  useGetWorksQuery,
} from "store/work/workApi";

import Select from "react-select";
import LazyLoad from "components/LazyLoad";


const Works = () => {
  const [selectedClient, setSelectedClient] = useState("");
  const [selectedIndustry, setSelectedIndustry] = useState("");
  const [selectedService, setSelectedService] = useState("");
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const [allCompanies, setAllCompanies] = useState([]);
  const [availServices, setAvailServices] = useState([]);
  const [allServices, setAllServices] = useState([]);

  const [currentPage, setcurrentPage] = useState(1);
  const limit = 12;
  const [workItems, setworkItems] = useState([]);

  const getFilterQueryString = () => {
    let baseUrl = "";

    if (selectedIndustry) {
      baseUrl += `&filters[industry][title][$eqi]=${selectedIndustry}`;
    }
    if (selectedClient) {
      baseUrl += `&filters[company][title][$eqi]=${selectedClient}`;
    }
    if (selectedService) {
      baseUrl += `&filters[services][title][$eqi]=${selectedService}`;
    }

    baseUrl += `&pagination[page]=${currentPage}&pagination[pageSize]=${limit}`;

    return baseUrl;
  };

  /* QUERIES */

  const {
    data: works,
    isLoading,
    refetch,
    isFetching,
  } = useGetWorksQuery({
    query: getFilterQueryString(),
  });
  //console.log(meta);
  const { data: industries, isLoading: isIndustriesDataLoading } =
    useGetIndustriesQuery();
  const { data: services, isLoading: isServicesDataLoading } =
    useGetServicesQuery();
  const { data: companies, isLoading: isCompaniesDataLoading } =
    useGetCompaniesQuery();

  useEffect(() => {
    if (works?.data?.length > 0 && works?.meta) {
      if (currentPage > 1) {
        setworkItems((prevItems) => [...prevItems, ...works?.data]);
      } else {
        setworkItems(works?.data);
      }
    }
  }, [works]);


  useEffect(() => {
    /** set currentpage to 1 if filter applied */
    if (selectedIndustry || selectedClient || selectedService) {
      setcurrentPage(1);
      refetch();
    }
  }, [selectedIndustry, selectedClient, selectedService]);

  useEffect(() => {
    if (currentPage) {
      refetch();
    }
  }, [currentPage]);

  useEffect(() => {
    if (selectedIndustry) {
      setSelectedClient("");
      setSelectedService("");
    }
  }, [selectedIndustry])

  useEffect(() => {
    if (selectedClient) {
      setSelectedService("");
    }
  }, [selectedClient])

  useEffect(() => {
    if (companies) {
      setAllCompanies(companies);
      setFilteredCompanies(companies);
    }
    if (services) {
      setAllServices(services);
      setAvailServices(services);
    }
  }, [companies, services]);

  useEffect(() => {
    if (works?.data && allCompanies && selectedIndustry) {
      const companyTitles = works.data
        .filter(work => work?.attributes?.industry?.data?.attributes?.title === selectedIndustry)
        .map(work => work?.attributes?.company?.data?.attributes?.title);

      if (companyTitles.length > 0) {
        const uniqueCompanyTitles = [...new Set(companyTitles)];
        const filteredCompaniesFromWorks = uniqueCompanyTitles
          .map(title => allCompanies.find(company => company?.attributes?.title === title))
          .filter(company => company);

        if (!selectedClient) {
          setFilteredCompanies(filteredCompaniesFromWorks);
        }
      }
    } else {
      setFilteredCompanies(allCompanies || []);
    }
  }, [works?.data, selectedIndustry, allCompanies]);



  useEffect(() => {
    if (selectedClient && works.data && allServices) {

      const serviceTitles = works.data
        .filter(work => work?.attributes?.company?.data?.attributes?.title === selectedClient)
        .flatMap(work => work?.attributes?.services?.data.map(service => service?.attributes?.title));

      // removingduplicates and find the available services
      const filteredAvailableServices = [...new Set(serviceTitles)]
        .map(title => allServices.find(service => service?.attributes?.title === title));

      if (!selectedService) {
        setAvailServices(filteredAvailableServices);
      }

    } else {
      setAvailServices(allServices || []);
    }
  }, [works?.data, selectedClient, allServices]);

  const handleReset = () => {
    setSelectedClient("");
    setSelectedIndustry("");
    setSelectedService("");
    setFilteredCompanies(allCompanies);
    setAvailServices(allServices);
  };


  const workListHTML = workItems?.map((work, index) => {
    return (
      <Link
        to={`/works/${work?.attributes?.slug}`}
        className="card-item"
        key={`work_${index}`}
      >
        <div className="hover-image-container">
          <LazyLoad
            src={work?.attributes?.thumbnail?.data?.attributes?.formats?.small?.url}
            dataSrc={work?.attributes?.thumbnail?.data?.attributes?.url}
          />
        </div>
        <div className="copy-wrap">
          <p className="name">
            {work?.attributes?.brand?.data?.attributes?.title}
          </p>
          <div className="rolling-copy-wrap">
            <p className="discr">{work?.attributes?.title}</p>
          </div>
        </div>
      </Link>
    );
  });

  const colourStyles = {
    menu: (provided) => ({
      ...provided,
      borderRadius: "10px",
      padding: "0 10px",
    }),
    control: (styles) => ({
      ...styles,
      backgroundColor: "transparent",
      borderColor: "black",
      borderRadius: 25,
      boxShadow: "none",
      ":hover": {
        borderColor: "black",
        boxShadow: "none",
        outline: "none",
      },
    }),
    option: (styles, { isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
            ? "#f2f2f2"
            : isFocused
              ? "#f2f2f2"
              : undefined,
        color: isDisabled ? "#ccc" : isSelected ? "#000" : "#000",
        cursor: isDisabled ? "not-allowed" : "default",

        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled
            ? isSelected
              ? "#FDAF9B"
              : "#AFC4FF"
            : undefined,
          borderRadius: 20,
        },
      };
    },
    input: (styles) => ({ ...styles }),
    placeholder: (styles) => ({ ...styles, padding: "0 5px" }),
    singleValue: (styles, { data }) => ({ ...styles, borderRadius: 20 }),
  };

  return (
    <div className="work-page" data-scroll-section>
      <div className="work-wrapper container">
        <div className="work-banner">
          <h2>Work</h2>
          <p>
            We're here to help you unlock the full potential of the internet by
            delivering breakthrough work tailored for the post digital, consumer
            controlled world.
          </p>
        </div>
      </div>
      <div className="select-ct container">
        <p className="label-select">Filter by</p>
        <ul className="select-wrapper">
          <li>
            <Select
              value={{
                value: selectedIndustry || "Industry",
                label: selectedIndustry || "Industry",
              }}
              onChange={(input) => setSelectedIndustry(input.value)}
              options={industries?.map((industry, index) => ({
                value: industry?.attributes?.title,
                label: industry?.attributes?.title,
              }))}
              className="custom-select"
              styles={colourStyles}
            />
          </li>
          <li>
            <Select
              value={{
                value: selectedClient || "Client",
                label: selectedClient || "Client",
              }}
              onChange={(input) => setSelectedClient(input.value)}
              options={filteredCompanies?.map((company, index) => ({
                value: company?.attributes?.title,
                label: company?.attributes?.title,
              }))}
              className="custom-select"
              styles={colourStyles}
            />
          </li>
          <li>
            <Select
              value={{
                value: selectedService || "Service",
                label: selectedService || "Service",
              }}
              onChange={(input) => setSelectedService(input.value)}
              options={availServices.map((service) => ({
                value: service?.attributes?.title,
                label: service?.attributes?.title,
              }))}
              className="custom-select"
              styles={colourStyles}
            />
          </li>
        </ul>
        {selectedClient || selectedIndustry || selectedService ? (
          <a onClick={handleReset} className="reset-btn">
            Reset
          </a>
        ) : null}
      </div>
      <div className="work-card container">
        {isLoading ? (
          <div className="spinner spinner-works">
            <span></span>
          </div>
        ) : (
          <>
            {works?.data?.length > 0 ? (
              <div className="card-wrapper">{workListHTML}</div>
            ) : (
              <p className="empty-filter-result">
                No results found. Please try resetting.
              </p>
            )}
          </>
        )}

        {currentPage < works?.meta?.pagination?.pageCount && (
          <div className="show-more-wrap">
            {isFetching ? (
              <div className="spinner spinner-works">
                <span></span>
              </div>
            ) : (
              <a
                className="show-more-button"
                onClick={() => setcurrentPage((prevPage) => prevPage + 1)}
              >
                Show More
              </a>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Works;
