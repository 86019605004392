import { Outlet, useLocation } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import { useEffect, useRef } from "react";
import { ScrollSmoother } from "gsap/ScrollSmoother";
import gsap from "gsap";

function Layout(props) {
  const location = useLocation();
  const isHome = location.pathname === "/";

  const containerRef = useRef(null);

  useEffect(() => {
    gsap.registerPlugin(ScrollSmoother);

    const smoother = ScrollSmoother.create({
      wrapper: containerRef.current,
      content: containerRef.current.querySelector(".scroll-content"),
      smooth: 0.8, // Adjust smoothness
      effects: true, // Apply data-speed and data-lag effects
    });

    return () => {
      smoother.kill();
    };
  }, []);

  return (
    <>
      <div className="zIndex-box "></div>

      <div
        className={isHome ? "home-page" : "home-page-all"}
        data-scroll-container
        ref={containerRef}
      >
        <Header />
        <div className="scroll-content">
          <main>
            <Outlet />
          </main>
          <Footer />
        </div>
      </div>
    </>
  );
}

export default Layout;
